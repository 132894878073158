<template>
    <v-main>
        <div class="main-content d-flex flex-column _local mt-0">
            <Card class="card-custom _height br-0">
                <template #title>
                    <v-tabs
          v-model="selectedTab"
          class="main-tabs"
          background-color="var(--white)"
      >
        <v-tab v-if="!isAgent" to="/localization/admin">
          takemybet.pro
        </v-tab>
        <v-tab to="/localization/agent">
            {{ domain }}
        </v-tab>
      </v-tabs>
                </template>
                <template #content>
                    <router-view>
                    </router-view>
                </template>
            </Card>
        </div>
    </v-main>
</template>

<script>
import { mapGetters } from "vuex";
    export default {
        name: 'LocalizationsView',
        components:{
        },
        beforeRouteEnter(to, from, next){
    next(vm => {
      vm.$store.commit('setPageName', 'local')
    });
    },
        data(){
            return{
                domain: window.location.hostname.includes("take")
                ? process.env.VUE_APP_API_DOMAIN_FIRST
                : process.env.VUE_APP_API_DOMAIN_SECOND,
                showLocalization: true,
                showLegalLocalization: false,
                selectedTab: 0,
            }
        },
        mounted() {
        },
        computed:{
            ...mapGetters({
                tokenUser: 'tokenUser',
            }),
            isAgent() {
                return this.tokenUser === 'agent';
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>